<template>
  <div style="background-color: white;padding: 10px 0 10px 10px;border-bottom: 1px double #cbcbcb">
    <img v-if="langNum === 2" src="https://news-1259740107.cos.ap-singapore.myqcloud.com/logo%2Fsgtop.png" style="width: 120px;"/>
    <img v-if="langNum === 1" src="https://news-1259740107.cos.ap-singapore.myqcloud.com/logo%2Fen_logo.png" style="width: 120px;"/>
    <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" size="mini" @click="openApp" style="float: right; margin-right: 20px;font-size: 1em">打开APP</van-button>
  </div>
  <van-divider />
  <div style="background-color: white;padding: 5%;">
    <div>
      <h2>{{ct.title}}</h2>
      <div style="color: #999999; font-size: 0.7em;margin: 10px 0;display: flex" @click="toAuthor(ct.userId)">
        <img :src="info.image" style="width: 25px;border-radius: 100px"  alt=""/>
        <span style="display: flex;align-self: center;margin-left: 5px">
          {{info.name}}
        </span>
      </div>
      <p style="color: #999999; font-size: 0.6em;margin: 10px 0">{{ct.viewTime}}</p>
      <img v-if="(ct.type === 1 || ct.type === 3) && ct.coverImgList[0]" style="width: 100%;display: block;margin: 10px auto;border-radius: 15px;object-fit: contain" :src="ct.coverImgList[0].url" />
      <div class="contentImg htmlCss" style="font-size: 1em;" v-if="ct.type === 1 || ct.type === 3" v-html="ct.content" />
      <div v-show="ct.type === 2" style="width: 90vw;display: block;margin: auto;" id="abcdee"></div>
    </div>
  </div>

  <footer class="footer">{{footInfo}}</footer>
</template>

<script>
import {ref} from "vue";
import axios from "axios";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import { Toast } from "vant";
import Player from "xgplayer";
import {Locale} from "vant/lib/";
import zhCN from "vant/es/locale/lang/zh-CN";
import enUS from "vant/es/locale/lang/en-US";

const videoConfig = () => {
  const { t } = useI18n();
  return {
    id: 'abcdee',
    url: 'https://lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
    errorTips: t('message.videoError'),
    fitVideoSize: 'fixWidth',
    fluid: true,
  };
};
const cot = () => {
  return {
    id: 0,
    title: '',
    content: '',
    type: 1,
    author: '',
    url: '',
    coverImgList: [{
      url: ''
    }],
  };
};

export default {
  name: "content",
  setup() {
    const {t} = useI18n()
    const ct = ref(cot())
    const player = ref()
    const lang = ref('zh-CN');
    const langNum = ref(1);
    const vpc = ref(videoConfig());
    const router = useRouter()
    const onClickLeft = () => history.back();
    const i18n = useI18n();
    const footInfo = ref('@SG Chat All rights reserved.');
    const info = ref({
      id: '',
      image: '',
      name: ''
    })

    const openApp = () => {
      const u = window.navigator.userAgent;

      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      if (isAndroid) {
        location.href = 'https://play.google.com/store/apps/details?id=com.singhot.sgchat';
      } else {
        location.href = 'https://apps.apple.com/us/app/sg-chat/id1606137738';
      }
    }
    const getUserInfo = async (aid) => {
      await axios.get('rss/author', {
        params: {
          id: aid
        }
      }).then(e => {
        info.value = e.data.data;
        document.title = info.value.name
      });
    }
    const toAuthor = (id) => {
      router.push({
        name: "person",
        params: {
          id: id,
        }
      });
    }
    axios.get('rss/footInfo').then((e) => {
      footInfo.value = e.data.data;
    });

    const getCt = async () => {
      await axios.get('rss/content', {
        params: {
          id: router.currentRoute.value.params.id
        }
      }).then(e => {
        ct.value = e.data.data
        getUserInfo(ct.value.userId);
        if (ct.value.type === 2) {
          vpc.value.url = ct.value.content
          vpc.value.poster = ct.value.coverImgList[0].url
          player.value = new Player(vpc.value);
        }


        const metas = document.createElement("META");
        const creatArr = [
          { charset: "utf-8" },
          { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
          //视图缩放
          { name: "viewport", content: "width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no,minimal-ui" },
          { property: "og:determiner", content: ct.value.author},
          { property: "og:site_name", content: ct.value.author },
          { property: "og:type", content: ct.value.title },
          { property: "og:url", content: "https://sg.singhot.com/#/content/"+router.currentRoute.value.params.id },
          { property: "og:title", content: ct.value.title },
          { property: "og:description", content: ct.value.content.replace(/<[^>]*>/g, '') },
          { property: "og:image", content: ct.value.coverImgList[0].url },
        ];

        //将单个路由设定的meta加到creatArr数组中
        //局部meta和全局meta重复时，局部meta替换全局meta

        //将设定的值写入文档片段
        const creatFrag = document.createDocumentFragment();
        creatArr.forEach(ele => {
          creatFrag.append(metas.cloneNode());
          Object.entries(ele).forEach(item => {
            creatFrag.lastChild.setAttribute(item[0], item[1]);
          });
        });

        //将文档片段写入head
        document.head.prepend(creatFrag);
        document.title = ct.value.title

        Toast.clear();
      }).catch(() => {
        Toast.fail(t('message.error'));
      })
    }
    const changeLang = () => {
      if (lang.value === "zh-CN") {
        langNum.value = 2;
        Locale.use("zh-CN", zhCN);
        i18n.locale.value = 'zh'
      } else {
        langNum.value = 1;
        Locale.use('en-US', enUS);
        i18n.locale.value = 'en'
      }
    }
    getCt();
    changeLang();

    return{
      ct,
      info,
      footInfo,
      langNum,
      onClickLeft,
      toAuthor,
      openApp,
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
.contentImg img {
  width: 100%;
  height: auto;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 0.8em;
  color: #999999;
  text-align: center;
}
.htmlCss iframe {
  width: 90%;
  display: block;

}
.htmlCss p {
  color: #333333;
  --tt-darkmode-color: #A3A3A3;
  font-size: 1.2em;
  line-height: 1.8em;
  letter-spacing: 1px;
  font-family: -apple-system,helvetica,sans-serif;
}
.htmlCss img {
  border-radius: 5px;
  margin: 20px 0;
  box-shadow: 0 0 10px 1px #A3A3A3;
}
</style>
